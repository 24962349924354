const APPLICATIONS = {
  HOME: `HOME`,
  ABOUT_US: `ABOUT_US`,
  CASE_STUDIES: "CASE_STUDIES",
  CAREER: "CAREER",
  CAREER_DETAILS: "CAREER_DETAILS",
  CONTACT_US: "CONTACT_US",
  PRIVACY_POLICY: "PRIVACY_POLICY",
  TERMS_AND_CONDITIONS: "TERMS_AND_CONDITIONS",
  COOKIE_POLICY: "COOKIE_POLICY",
  REFUND_POLICY: "REFUND_POLICY",
  PAGE_NOT_FOUND: "PAGE_NOT_FOUND",
  NEWS: "NEWS",
  NEWS_DETAILS: "NEWS_DETAILS",
  INITIATIVES: "INITIATIVES",
  WOMEN_EDUCATION: 'WOMEN_EDUCATION',
  WOMEN_EDUCATION_DETAILS: 'WOMEN_EDUCATION_DETAILS',
  GREEN_INITIATIVES: 'GREEN_INITIATIVES',
  GREEN_INITIATIVES_DETAILS: 'GREEN_INITIATIVES_DETAILS',
} as const;

type TApplications = (typeof APPLICATIONS)[keyof typeof APPLICATIONS];

const APPLICATION_URLS: Record<TApplications, string> = {
  [APPLICATIONS.HOME]: `/`,
  [APPLICATIONS.ABOUT_US]: `/about-us`,
  [APPLICATIONS.CASE_STUDIES]: `/case-studies/*`,
  [APPLICATIONS.CAREER]: "/career",
  [APPLICATIONS.CAREER_DETAILS]: "/career/:id",
  [APPLICATIONS.CONTACT_US]: "/contact-us",
  [APPLICATIONS.PRIVACY_POLICY]: "/privacy-policy",
  [APPLICATIONS.COOKIE_POLICY]: "/cookie-policy",
  [APPLICATIONS.REFUND_POLICY]: "/refund-policy",
  [APPLICATIONS.TERMS_AND_CONDITIONS]: "/terms-and-conditions",
  [APPLICATIONS.PAGE_NOT_FOUND]: "*",
  [APPLICATIONS.NEWS]: "/news",
  [APPLICATIONS.NEWS_DETAILS]: "/news/:id",
  [APPLICATIONS.INITIATIVES]: "/initiatives",
  [APPLICATIONS.WOMEN_EDUCATION]: "/women-education",
  [APPLICATIONS.WOMEN_EDUCATION_DETAILS]: "/women-education/:id",
  [APPLICATIONS.GREEN_INITIATIVES]: "/green-initiative",
  [APPLICATIONS.GREEN_INITIATIVES_DETAILS]: "/green-initiative/:id",
};

export { APPLICATIONS, APPLICATION_URLS };
