import { useNavigate } from "react-router-dom";

import { AppBar, Drawer, IconButton, Stack } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { StyledToolbar } from "./style";
import v2sLogo from "../../assets/v2s-logo.png";
import { NAVBAR_ROUTES } from "./config/constant";
import COLORS from "../../styles/colors";
import useToggle from "../../hooks/useToggle";
import MobileViewDrawer from "./components/MobileViewDrawer";
import { APPLICATION_URLS } from "../../Routing/config/appsConfig";
import MenuOption from "./components/MenuOption";

const NavBar: React.FC = () => {
  const navigate = useNavigate();
  const { toggle: toggleMobileDrawer, value: isMobileDrawerOpen } = useToggle();

  const handleNavigateHome = () => {
    navigate(APPLICATION_URLS.HOME);
  };

  return (
    <>
      <AppBar
        position="sticky"
        color="inherit"
        sx={{ py: 1, px: { xs: "1%", sm: "1%", md: "1%", lg: "6%" } }}
      >
        <StyledToolbar disableGutters>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <img
              alt="CompanyBrandLogo"
              src={v2sLogo}
              onClick={handleNavigateHome}
            />
          </Stack>

          <Stack direction="row" spacing={0} alignItems="center">
            {NAVBAR_ROUTES.map((route, index) => {
              return (
                <MenuOption
                  key={index}
                  index={index}
                  option={route}
                />
              )
            })}

            <IconButton
              onClick={toggleMobileDrawer}
              sx={{
                display: { sm: "block", md: "none" },
              }}
            >
              <MenuIcon
                sx={{
                  display: { sm: "block", md: "none" },
                  color: COLORS.LIGHT_GREY,
                  borderRadius: 1,
                  border: `1px solid ${COLORS.LIGHT_GREY}`,
                  p: 0.5,
                }}
              />
            </IconButton>
          </Stack>
        </StyledToolbar>
      </AppBar>

      <Drawer
        variant="temporary"
        open={isMobileDrawerOpen}
        onClose={toggleMobileDrawer}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { md: "block", lg: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 300 },
        }}
      >
        <MobileViewDrawer toggleMobileDrawer={toggleMobileDrawer} />
      </Drawer>
    </>
  );
};

export default NavBar;
