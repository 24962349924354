const IMAGES_MAP = {
    MSME_LOGO: `${process.env.REACT_APP_ASSETS_BASE_URL}/msme-logo.png`,
    CONFLUENT_LOGO: `${process.env.REACT_APP_ASSETS_BASE_URL}/confluent-logo.png`,
    SCGT_LOGO: `${process.env.REACT_APP_ASSETS_BASE_URL}/scgt-logo.png`,
    NASSCOM_LOGO: `${process.env.REACT_APP_ASSETS_BASE_URL}/nasscom-logo.png`,
    MONGODB_LOGO: `${process.env.REACT_APP_ASSETS_BASE_URL}/mongodb-logo.png`,
    GOODFIRMS_LOGO: `${process.env.REACT_APP_ASSETS_BASE_URL}/goodfirms-logo.png`,
};

export const OUR_ASSOCIATION_IMAGES_LIST = [
    {
        image: IMAGES_MAP.MSME_LOGO,
        path: "https://msme.gov.in/",
        key: "msme",
    },
    {
        image: IMAGES_MAP.CONFLUENT_LOGO,
        path: "https://www.confluent.io/partners/",
        key: "confluent",
    },
    {
        image: IMAGES_MAP.SCGT_LOGO,
        path: "https://www.scgt.org.in/",
        key: "scgt",
    },
    {
        image: IMAGES_MAP.NASSCOM_LOGO,
        path: "https://nasscom.in/",
        key: "nasscom",
    },
    {
        image: IMAGES_MAP.MONGODB_LOGO,
        path: "https://www.mongodb.com/company/partners",
        key: "mongodb",
    },
    {
        image: IMAGES_MAP.GOODFIRMS_LOGO,
        path: "https://www.goodfirms.co/company/v2stech-solutions-private-limited",
        key: "goodfirms",
    },
];

export const WHATSAPP_NUMBER = "918779742488";
export const SALES_EMAIL = "sales@v2stech.com";

export const CASE_STUDY_STORIES: {
    description: string;
    id: string;
    image: string;
    title: string;
    path: string;
}[] = [
    {
        id: "qfix",
        title: "Improved platform with 12 major and 50 minor releases in 3 years. Every release was on-time and within budget.",
        description:
            "Custom checkout solution capable of handling 10+ million payment transactions monthly",
        image: `${process.env.REACT_APP_ASSETS_BASE_URL}/case-studies/case-studies-listing/qfix-logo.png`,
        path: "/case-studies/qfix-online-integrated-payment-and-billing-service",
    },
    {
        id: "fissara",
        title: "Transforming SaaS platform to multitenant architecture & build multiple products",
        description:
            "Product suite for waste movements, bulk haulage, people and property management",
        image: `${process.env.REACT_APP_ASSETS_BASE_URL}/case-studies/case-studies-details/FissaraLogo.webp`,
        path: "/case-studies/fissara-mobile-workforce-management-software",
    },
    {
        id: "pathlock",
        title: "Asynchronous business process execution handles high volume through user centred design",
        description:
            "Transforming product digitally, where user experience takes center stage for unparalleled innovation.",
        image: `${process.env.REACT_APP_ASSETS_BASE_URL}/case-studies/case-studies-listing/pathlock-logo.png`,
        path: "/case-studies/pathlock-platform-for-company-auditing",
    },
    {
        id: "ripplehire",
        title: "SaaS platform for employee referrals and internal mobility",
        description:
            "The leading SaaS recruitment platform significantly improved the customer onboarding cycle",
        image: `${process.env.REACT_APP_ASSETS_BASE_URL}/case-studies/case-studies-listing/ripple-hire-logo.png`,
        path: "/case-studies/ripplehire-talent-acquisition-cloud-platform",
    },
];

export const CONTACT_US_EMAIL = "itsupport@v2stech.com";
export const CONTACT_US_PHONE = "001(862)2180998";

export const FISSARA_CONTACT_US_EMAIL = "grievance@fissaramanage.in";
export const FISSARA_CONTACT_US_PHONE = "001(862)2180998";

export const GOOGLE_CHROME_COOKIES =
    "https://support.google.com/accounts/answer/32050";
export const IE_Cookies = "http://support.microsoft.com/kb/278835";
export const FIREFOX_COOKIES =
    "https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored";
export const SAFARI_COOKIES =
    "https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac";
