const COLORS = {
  PRIMARY_BLUE: "#33a7e1",
  LIGHT_BLUE: "#d5eafd",
  DARK_BLUE: "#134c89",
  BLACK: "#000",
  WHITE: "#FFF",
  LIGHT_GREY: "#cccccc",
  GREY_TEXT: "#6D6D6D",
  BACKGROUND_GREY: "#f4f4f4",
  FORM_BACKGROUND_GREY: "#F7F7F7",
  SKY_BLUE: "#609bdc",
  OCEAN_BLUE: "#2a90c7",
  NAVY_BLUE: "#34314b",
  FOOTER_BACKGROUND_COLOR: "#10182f",
  HOVER_YELLOW: "#E1FF00",
  LIGHT_SKY_BLUE: "#76CDFD",
  DEFAULT_GREY: "#f8f9f0",
};

export default COLORS;
