import { APPLICATION_URLS } from "../../../Routing/config/appsConfig";
import { removeAsterisk } from "../../../utils/helpers";

export const NAVBAR_OPTIONS = {
  HOME: "Home",
  ABOUT_US: "About Us",
  CASE_STUDIES: "Case Studies",
  CAREER: "Career",
  CONTACT_US: "Contact Us",
  NEWS: "News",
  INITIATIVES: "Initiatives",
  WOMEN_EDUCATION: "Women Education",
  GREEN_INITIATIVE: "Green Initiatives",
};

export const NAVBAR_ROUTES = [
  {
    label: NAVBAR_OPTIONS.HOME,
    path: APPLICATION_URLS.HOME,
  },
  {
    label: NAVBAR_OPTIONS.ABOUT_US,
    path: APPLICATION_URLS.ABOUT_US,
  },
  {
    label: NAVBAR_OPTIONS.CASE_STUDIES,
    path: removeAsterisk(APPLICATION_URLS.CASE_STUDIES),
  },
  {
    label: NAVBAR_OPTIONS.CAREER,
    path: APPLICATION_URLS.CAREER,
  },
  {
    label: NAVBAR_OPTIONS.CONTACT_US,
    path: APPLICATION_URLS.CONTACT_US,
  },
  {
    label: NAVBAR_OPTIONS.NEWS,
    path: APPLICATION_URLS.NEWS,
  },
  {
    label: NAVBAR_OPTIONS.INITIATIVES,
    path: APPLICATION_URLS.INITIATIVES,
    subRoutes: [
      {
        label: NAVBAR_OPTIONS.WOMEN_EDUCATION,
        path: APPLICATION_URLS.INITIATIVES.concat("/women-education"),
      },
      {
        label: NAVBAR_OPTIONS.GREEN_INITIATIVE,
        path: APPLICATION_URLS.INITIATIVES.concat("/green-initiative"),
      },
    ]
  },
];
