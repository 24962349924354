export const FOR_BUSINESS_QUERIES = "For Business Queries:";

export const PHONE = "001(862)2180998";

export const EMAIL = "sales@v2stech.com";

export const COPY_CONTACT = "Copy contact";

export const COPY_EMAIL = "Copy email";

export const socialMediaUrls = {
  facebook: "https://www.facebook.com/V2STechSolutions",
  linkedIn: "https://www.linkedin.com/company/v2stech-solutions-pvt-ltd/",
};
