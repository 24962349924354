import { useLocation, useNavigate } from "react-router-dom";
import { FC } from "react";
import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";

import COLORS from "../../../styles/colors";
import { StyledNavBarText } from "../style";
import { NAVBAR_ROUTES } from "../config/constant";

type option = {
  label: string;
  path: string;
}

type MenuOptionProps = {
  index: number;
  option: option & {
    subRoutes?: option[];
  };
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: COLORS.WHITE,
      color: COLORS.BLACK,
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: `1px solid ${COLORS.LIGHT_GREY}`,
      marginLeft: 70,
      marginTop: 40,
    },
}));

const MenuOption: FC<MenuOptionProps> = ({ index, option }) => { 
  const { pathname: currentPath } = useLocation();
  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  if(!option.subRoutes) {
    return (
      <StyledNavBarText
        sx={{
          display: { xs: "none", md: "block" },
          color: currentPath === option.path ? COLORS.PRIMARY_BLUE : COLORS.BLACK,
          cursor: "pointer",
          marginRight: index < NAVBAR_ROUTES.length - 1 ? 4 : 0,
          ":hover": {
            color: COLORS.PRIMARY_BLUE,
            transition: "color 0.5s",
          },
        }}
        onClick={() => handleNavigate(option.path)}
      >
        {option.label}
      </StyledNavBarText>
    )
  }

  return (
      <HtmlTooltip
          title={
            <>
              {option.subRoutes?.map((subRoute: option) => (
                <StyledNavBarText
                  key={subRoute.path}
                  sx={{
                    display: { xs: "none", md: "block" },
                    cursor: "pointer",
                    color: currentPath === subRoute.path ? COLORS.PRIMARY_BLUE : COLORS.BLACK,
                    marginRight: index < NAVBAR_ROUTES.length - 1 ? 4 : 0,
                    ":hover": {
                      color: COLORS.PRIMARY_BLUE,
                      transition: "color 0.5s",
                    },
                    marginBlock: 1,
                    marginInline: 1,
                  }}
                  onClick={() => handleNavigate(subRoute.path)}
                  >
                  {subRoute.label}
                </StyledNavBarText>
              ))}
            </>
          }
        >
          <StyledNavBarText
            sx={{
              display: { xs: "none", md: "block" },
              color: currentPath.includes(option.path) ? COLORS.PRIMARY_BLUE : COLORS.BLACK,
              cursor: "pointer",
              marginRight: 4,
              ":hover": {
                color: COLORS.PRIMARY_BLUE,
                transition: "color 0.5s",
              },
            }}
          >
            {option.label}
          </StyledNavBarText>
        </HtmlTooltip>
  )
}

export default MenuOption;